'use client';
import React from 'react';
import { Link } from '@/navigation';
import { motion } from 'framer-motion';
import SectionMarkdown from '../markdown/section';

type Props = {
  content?: any;
  image?: string;
};

const ChallengeSection = ({ content, image }: Props) => {
  return (
    <div className="section py-6 sm:py-8">
      <div className="flex flex-col flex-1 py-4 sm:py-8 sm:pr-8">
        <div className="flex flex-col gap-2">
          <SectionMarkdown content={content} />
          <Link href={'/contact'}>
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 1 }}
              className="mt-4 neon-button"
            >
              Contact Us
            </motion.button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChallengeSection;

export const languages = [
  {
    id: 'en',
    title: 'English',
    isDefault: true,
    icon: 'https://twemoji.maxcdn.com/v/latest/svg/1f1ec-1f1e7.svg',
  },
  {
    id: 'es',
    title: 'Spanish',
    icon: 'https://twemoji.maxcdn.com/v/latest/svg/1f1ea-1f1f8.svg',
  },
  {
    id: 'ar',
    title: 'Arabic',
    icon: 'https://twemoji.maxcdn.com/v/latest/svg/1f1e6-1f1ea.svg',
  },
  {
    id: 'zh',
    title: 'Chinese',
    icon: 'https://twemoji.maxcdn.com/v/latest/svg/1f1e8-1f1f3.svg',
  },
  {
    id: 'fr',
    title: 'French',
    icon: 'https://twemoji.maxcdn.com/v/latest/svg/1f1eb-1f1f7.svg',
  },
  {
    id: 'de',
    title: 'German',
    icon: 'https://twemoji.maxcdn.com/v/latest/svg/1f1e9-1f1ea.svg',
  },
  {
    id: 'nl',
    title: 'Dutch',
    icon: 'https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1f1.svg',
  },
  // Uncomment and add if needed
  // {
  //   id: 'no',
  //   title: 'Norwegian',
  //   icon: 'https://twemoji.maxcdn.com/v/latest/svg/1f1f3-1f1f4.svg',
  // },
];

export const i18n = {
  languages,
  base: languages.find((item) => item.isDefault)?.id || languages[0].id,
  locales: languages.map((item) => item.id),
};

export const googleTranslateLanguages = languages.map(
  ({ id, title, icon }) => ({
    id,
    title,
    icon,
  })
);

export const getCurrentLanguage = (id: string) =>
  languages.find((item) => item.id === id)?.title;

export const getCurrentImage = (locale: string) =>
  languages.find((item) => item.id === locale)?.icon;

'use client';

import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { Banner } from '@/typings/pages';

type HeroProps = {} & Banner;

const HeroBanner: FC<HeroProps> = ({ heading, description, video }) => {
  const handleButtonClick = () => {
    window.scrollBy({ top: window.innerHeight, behavior: 'smooth' });
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col flex-1 relative max-h-full min-h-[50vh] sm:min-h-[90vh] bg-black vimeo-container overflow-hidden">
        <iframe
          title="Banner Video"
          width="100%"
          height="100%"
          allowFullScreen
          allow="autoplay; fullscreen"
          src={video}
        />
        {/* Layer */}
        <div className="h-full w-full absolute top-0 left-0 bg-black bg-opacity-40 p-20 flex-1 flex flex-col">
          <div className="flex-1 flex flex-col">
            <div className="flex-1 flex flex-col items-start justify-center gap-4 text-center">
              <div className="w-8 h-2 bg-[var(--app-bg-light)]"></div>
              <h1 className="text-3xl sm:text-5xl text-default-50 text-left urw-din-medium font-extrabold">
                {heading}
              </h1>
              <motion.p
                className="text-2xl sm:text-4xl text-default-200 max-w-sm text-left urw-din-medium"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                {description}
              </motion.p>
              <motion.div
                className="flex items-center absolute bottom-6 sm:bottom-8 cursor-pointer"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                onClick={handleButtonClick}
              >
                <motion.img
                  width={48}
                  height={48}
                  alt="Scroll Trigger"
                  src="/icons/scroll.gif"
                  className="h-8 w-8 sm:h-12 sm:w-12"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;

import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/about-us.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/challenge-us.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/compare-slider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/contact-us.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/list-services.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/products-and-services.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/projects.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/slider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/home/stats.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/home/title.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/view/index.tsx");

'use client';

import React from 'react';
import Markdown from 'markdown-to-jsx';
import {
  AnimatedBlockquote,
  AnimatedH2,
  AnimatedList,
  AnimatedListItem,
  AnimatedParagraph,
} from './elements';

type Props = {
  content: any;
};

const SectionMarkdown: React.FC<Props> = ({ content }) => {
  return (
    <Markdown
      options={{
        overrides: {
          h2: {
            component: AnimatedH2,
          },
          blockquote: {
            component: AnimatedBlockquote,
          },
          p: {
            component: AnimatedParagraph,
          },
          ul: {
            component: AnimatedList,
          },
          li: {
            component: AnimatedListItem,
          },
        },
      }}
    >
      {content}
    </Markdown>
  );
};

export default SectionMarkdown;

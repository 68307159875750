'use client';

import React, { FC } from 'react';
import { motion } from 'framer-motion';
import PageLinesIcon from '@/components/icons/PageLines';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export const AnimatedH2: FC<{ children: React.ReactNode }> = ({ children }) => (
  <motion.h2
    className="text-2xl sm:text-3xl md:text-4xl text-[var(--app-color-green)] font-bold leading-tight"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.5, duration: 1 }}
  >
    {children}
  </motion.h2>
);

export const AnimatedBlockquote: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <motion.span
    className="text-base sm:text-xl font-bold flex items-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.2, duration: 1 }}
  >
    <span className="text-[var(--app-color-green)] flex items-center gap-1">
      <PageLinesIcon fill="currentColor" />
      {children}
    </span>
  </motion.span>
);

export const AnimatedParagraph: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <motion.p
    className="text-base font-medium text-default-500"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.8, duration: 1 }}
  >
    {children}
  </motion.p>
);

export const AnimatedList: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <ul className="flex flex-col gap-2 font-medium mt-1 sm:mt-2">{children}</ul>
);

export const AnimatedListItem: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <motion.li
    className="py-1"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 1, duration: 1 }}
  >
    <span className="flex items-center gap-2 cursor-pointer">
      <CheckCircleIcon className="h-5 w-5 text-[var(--app-color-green)]" />
      <span className="text-sm text-default-700">{children}</span>
    </span>
  </motion.li>
);

'use client';
import React from 'react';

type Props = React.ComponentPropsWithRef<'div'>;
type Ref = HTMLDivElement;
const View = React.forwardRef((props: Props, ref: React.Ref<Ref>) => {
  return <div {...props} ref={ref} />;
});

View.displayName = 'View';
export default View;

'use client';

import React from 'react';
import View from '../view';
import css from './style.module.scss';

interface AnimatedMaskImageProps {
  masks: any[];
}

const AnimatedMaskImage = ({ masks }: AnimatedMaskImageProps) => {
  const [c, setC] = React.useState(45);

  const animationFrameId = React.useRef(0);

  React.useEffect(() => {
    const draw = () => {
      setC((prevC) => prevC + 1);
      animationFrameId.current = requestAnimationFrame(draw);
    };

    animationFrameId.current = requestAnimationFrame(draw);

    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, [c]);

  return (
    <View className="absolute top-0 left-0 h-full w-full">
      <div
        className={css['mask-container']}
        style={{
          backgroundImage: `url(${masks?.[0]?.original})`,
        }}
      ></div>
      <div
        className={css['mask-container']}
        style={{
          backgroundImage: `url(${masks?.[1]?.original})`,
          maskImage: `linear-gradient(${c}deg, rgb(0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%)`,
          maskPosition: 'center',
        }}
      ></div>
    </View>
  );
};
export default AnimatedMaskImage;

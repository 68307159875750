'use client';
import React from 'react';
import { motion } from 'framer-motion';
import { cn, tv } from '@nextui-org/react';
import { mountAnimation } from '@/config/animation';

type Props = {
  tagline?: string;
  heading?: string;
  description?: string;
  mode?: 'dark' | 'light';
  center?: boolean;
};

const classNames = tv({
  slots: {
    base: '',
    tagline: 'font-medium leading-7',
    heading: 'text-3xl sm:text-4xl font-semibold tracking-tight',
    description: 'text-large font-medium mt-1',
  },
  variants: {
    mode: {
      dark: {
        tagline: 'text-default-500',
        heading: 'text-default-900',
        description: 'text-[var(--app-color-main)]',
      },
      light: {
        tagline: 'text-default-500',
        heading: 'text-default-50',
        description: 'text-default-400',
      },
    },
  },
});

const SectionTitle: React.FC<Props> = (props) => {
  const { tagline, heading, description } = classNames();
  return (
    <motion.div
      {...mountAnimation}
      className={cn(
        'section flex items-center p-4',
        props.center ? 'justify-center' : ''
      )}
    >
      <div
        className={cn(
          'relative flex flex-col py-12 flex-1',
          props.center ? 'max-w-4xl items-center' : ''
        )}
      >
        <div className={cn('flex flex-col', props.center ? 'text-center' : '')}>
          {props.tagline && (
            <span
              className={tagline({
                mode: props.mode,
              })}
            >
              {props.tagline}
            </span>
          )}
          {props.heading && (
            <h1
              className={heading({
                mode: props.mode,
              })}
            >
              {props.heading}
            </h1>
          )}
          <span aria-hidden="true" className="w-px h-px block" />
          {props.description && (
            <p
              className={description({
                mode: props.mode,
              })}
            >
              {props.description}
            </p>
          )}
        </div>
      </div>
    </motion.div>
  );
};
export default SectionTitle;

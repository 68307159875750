'use client';

import React from 'react';
import { motion } from 'framer-motion';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { mountAnimation } from '@/config/animation';
import { Card, CardBody, Image } from '@nextui-org/react';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { Gallery } from '@/typings/pages';

export default function Projects({ services }: { services: Gallery[] }) {
  return (
    <motion.section {...mountAnimation} className="section">
      <Swiper
        spaceBetween={8}
        autoplay
        modules={[Autoplay]}
        rewind
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          920: {
            slidesPerView: 4,
          },
        }}
      >
        {services &&
          services?.map((item, index) => (
            <SwiperSlide key={index}>
              <SlideCard {...item} />
            </SwiperSlide>
          ))}
      </Swiper>
    </motion.section>
  );
}

const SlideCard = ({ ...item }) => {
  const [isHover, setIsHover] = React.useState(false);

  return (
    <motion.div
      onHoverStart={(e) => setIsHover(true)}
      onHoverEnd={(e) => setIsHover(false)}
      className="hover:-translate-y-1 transition-all"
    >
      <Card
        isFooterBlurred
        radius="none"
        className="min-w-full rounded-lg cursor-pointer sm:min-w-52 md:min-w-56"
      >
        <CardBody className="overflow-visible p-0">
          <Image
            radius="none"
            shadow="none"
            width="100%"
            alt={item?.alt}
            className="w-full object-cover h-60 sm:h-44 md:h-52 lg:h-56"
            src={item?.small}
          />
        </CardBody>
        <motion.div
          initial={{
            height: 0,
          }}
          animate={{
            height: isHover ? '50%' : '25%',
          }}
          className="absolute bottom-0 left-0 w-full backdrop-blur-lg z-20  bg-black/40"
        >
          <h4 className="flex items-center gap-1 text-default-50 text-base font-medium p-2">
            <ArrowRightCircleIcon className="h-4 w-4" />
            {item?.name}
          </h4>
        </motion.div>
      </Card>
    </motion.div>
  );
};

'use client';

import React from 'react';
import { Link } from '@/navigation';
import AnimatedView from '../animation';
import { Content } from '@/typings/projects';
import { Button, Image } from '@nextui-org/react';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';

type Props = {
  data: Content[];
};

const ProductsAndServiceCards: React.FC<Props> = ({ data }) => {
  return (
    <AnimatedView>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
        {data &&
          data?.map((item, index) => (
            <Link
              key={index}
              className="single-latest cursor-pointer"
              href={{
                pathname: `/products-and-services/[slug]`,
                params: {
                  slug: item.slug as any,
                },
              }}
            >
              <Image
                removeWrapper
                radius="none"
                alt={item?.title?.toLowerCase() as string}
                src={item.image as string}
                className="h-full w-full object-cover sm:min-h-80 max-h-[480px]"
                classNames={{
                  img: 'h-full w-full object-cover max-h-[480px] sm:min-h-80',
                }}
              />
              <div className="overlay">
                <div className="box-holder">
                  <div className="content-box">
                    <span className="heading-text">{item.title}</span>
                    {/* <p className="line-clamp-5">{item.description}</p> */}
                    <div className="button mt-1">
                      <Button
                        isIconOnly
                        variant="light"
                        radius="full"
                        title="Go to service page"
                        size="lg"
                      >
                        <ArrowRightCircleIcon className="text-default-300 w-8 h-8" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </AnimatedView>
  );
};
export default ProductsAndServiceCards;

'use client';
import React from 'react';
import { Card, CardBody } from '@nextui-org/react';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import {
  FaceSmileIcon,
  CheckBadgeIcon,
  ArrowTrendingUpIcon,
  AcademicCapIcon,
} from '@heroicons/react/24/outline';
import { mountAnimation } from '@/config/animation';
const Stats = () => {
  const statics = [
    {
      name: 'Years Of Experience',
      icon: CheckBadgeIcon,
      number: {
        digit: 20,
        suffix: '+',
      },
    },
    {
      name: 'Talented Professionals',
      icon: AcademicCapIcon,
      number: {
        digit: 20,
        suffix: 'M',
      },
    },
    {
      name: 'Projects Delivered',
      icon: ArrowTrendingUpIcon,
      number: {
        digit: 80,
        suffix: '+',
      },
    },
    {
      name: 'Happy Customers',
      icon: FaceSmileIcon,
      number: {
        digit: 1.5,
        suffix: 'K',
      },
    },
  ];

  return (
    <section className="section py-12">
      <motion.div
        {...mountAnimation}
        viewport={{
          once: false,
        }}
        className="gap-3 grid grid-cols-2 sm:grid-cols-4"
      >
        {statics.map((item, index) => (
          <Card
            isPressable
            disableRipple
            key={index}
            className="bg-transparent rounded-none shadow-none"
          >
            <CardBody className="flex items-center flex-row gap-3 overflow-visible ">
              <div className="flex items-center p-2 rounded-sm bg-default-100">
                {<item.icon className="h-8 w-8 sm:h-10 sm:w-10" />}
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-xl sm:text-2xl md:text-3xl text-default-700">
                  <CountUp
                    start={0}
                    end={item.number.digit}
                    delay={2}
                    decimals={2}
                    decimal="."
                    redraw
                    decimalPlaces={1}
                    duration={3}
                  />
                  {item.number.suffix}
                </span>
                <p className="text-xs sm:text-sm text-default-500">
                  {item.name}
                </p>
              </div>
            </CardBody>
          </Card>
        ))}
      </motion.div>
    </section>
  );
};
export default Stats;

import { i18n } from './config/languages';
import { LocalePrefix, Pathnames } from 'next-intl/routing';

export const defaultLocale = i18n.base;
export const locales = i18n.locales;

// Define the pathnames object for localized links and URLs
export const pathnames = {
  // Root path
  '/': {
    en: '/',
    es: '/',
    ar: '/',
    zh: '/',
    fr: '/',
    de: '/',
    nl: '/',
  },

  // Projects
  '/projects': {
    en: '/projects',
    es: '/proyectos',
    ar: '/projects',
    zh: '/projects',
    fr: '/projets',
    de: '/projekte',
    nl: '/projecten',
  },
  '/projects/[slug]': {
    en: '/projects/[slug]',
    es: '/proyectos/[slug]',
    ar: '/projects/[slug]',
    zh: '/projects/[slug]',
    fr: '/projets/[slug]',
    de: '/projekte/[slug]',
    nl: '/projecten/[slug]',
  },

  // Products and Services
  '/products-and-services': {
    en: '/products-and-services',
    es: '/productos-y-servicios',
    ar: '/products-and-services',
    zh: '/products-and-services',
    fr: '/produits-et-services',
    de: '/produkte-und-dienstleistungen',
    nl: '/producten-en-diensten',
  },
  // Products and Services
  '/products-and-services/[slug]': {
    en: '/products-and-services/[slug]',
    es: '/productos-y-servicios/[slug]',
    ar: '/products-and-services/[slug]',
    zh: '/products-and-services/[slug]',
    fr: '/produits-et-services/[slug]',
    de: '/produkte-und-dienstleistungen/[slug]',
    nl: '/producten-en-diensten/[slug]',
  },

  // About
  '/about-greenconceptors': {
    en: '/about-greenconceptors',
    es: '/acerca-de-greenconceptors',
    ar: '/about-greenconceptors',
    zh: '/about-greenconceptors',
    fr: '/a-propos-de-greenconceptors',
    de: '/ueber-greenconceptors',
    nl: '/over-greenconceptors',
  },

  // News
  '/news-greenconceptors': {
    en: '/news-greenconceptors',
    es: '/noticias-greenconceptors',
    ar: '/news-greenconceptors',
    zh: '/news-greenconceptors',
    fr: '/nouvelles-greenconceptors',
    de: '/neuigkeiten-greenconceptors',
    nl: '/nieuws-greenconceptors',
  },
  '/news-greenconceptors/[slug]': {
    en: '/news-greenconceptors/[slug]',
    es: '/noticias-greenconceptors/[slug]',
    ar: '/news-greenconceptors/[slug]',
    zh: '/news-greenconceptors/[slug]',
    fr: '/nouvelles-greenconceptors/[slug]',
    de: '/neuigkeiten-greenconceptors/[slug]',
    nl: '/nieuws-greenconceptors/[slug]',
  },

  // Contact
  '/contact': {
    en: '/contact',
    es: '/contacto',
    ar: '/contact',
    zh: '/contact',
    fr: '/contact',
    de: '/kontakt',
    nl: '/contact',
  },
  '/privacy-policy': {
    en: '/privacy-policy',
    es: '/politica-de-privacidad',
    ar: '/contact',
    zh: '/contact',
    fr: '/politique-de-confidentialite',
    de: '/datenschutz',
    nl: '/privacybeleid',
  },
} satisfies Pathnames<typeof locales>;

// NOTE :- Can be use like that as well

export const localePrefix: LocalePrefix<typeof locales> = 'as-needed';

export const port = process.env.PORT || 3000;
export const host = process.env.VERCEL_URL
  ? `https://${process.env.VERCEL_URL}`
  : `http://localhost:${port}`;

'use client';
import React from 'react';
import SectionMarkdown from '../markdown/section';

type Props = {
  content?: any;
  image?: string;
};

const ContactSection = ({ content, image }: Props) => {
  return (
    <div className="">
      <div className="section py-6 sm:py-8">
        <div className="flex flex-col flex-1 py-4 sm:py-8 sm:pr-8">
          <div className="flex flex-col gap-2">
            <SectionMarkdown content={content} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;

'use client';

import React from 'react';
import { Button } from '@nextui-org/react';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider';
import AnimatedView from '../animation';
import { motion, AnimatePresence } from 'framer-motion';
import { MapPinIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Gallery } from '@/typings/pages';
import Markdown from 'markdown-to-jsx';

type Props = {
  images: Gallery[];
  content?: any;
};

const CompareSlider = ({ images, content }: Props) => {
  const [isLayerVisible, setIsLayerVisible] = React.useState(false);

  return (
    <AnimatedView>
      <section className="section">
        <div className="relative overflow-hidden rounded-lg sm:h-[520px]">
          <ReactCompareSlider
            boundsPadding={0}
            itemOne={
              <ReactCompareSliderImage
                alt={images?.[0]?.alt}
                src={images?.[0]?.small || images?.[0]?.original}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                alt={images?.[1]?.alt}
                src={images?.[1]?.small || images?.[1]?.original}
              />
            }
            keyboardIncrement="5%"
            position={50}
            className="h-full w-full"
          />
          <AnimatePresence>
            {isLayerVisible && (
              <motion.div
                className="absolute top-0 left-0 h-full w-full bg-black/50 backdrop-blur-xl flex flex-col gap-2 p-8 overflow-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => setIsLayerVisible(false)}
              >
                <Markdown
                  options={{
                    overrides: {
                      h2: {
                        component: (props) => (
                          <motion.h2
                            className="text-default-100 text-xl md:text-2xl lg:text-3xl font-semibold py-2"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            {props.children}
                          </motion.h2>
                        ),
                      },

                      p: {
                        component: (props) => (
                          <motion.p
                            className="text-default-200 text-base"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                          >
                            {props.children}
                          </motion.p>
                        ),
                      },
                      blockquote: {
                        component: (props) => (
                          <motion.span
                            className="flex items-center gap-1 text-default-300 text-sm sm:text-base italic"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                          >
                            <MapPinIcon className="h-4 w-4" />
                            {props.children}
                          </motion.span>
                        ),
                      },
                      img: {
                        component: (props) => null,
                      },
                    },
                  }}
                >
                  {content}
                </Markdown>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="absolute bottom-6 right-6 ">
            <span className="relative flex items-center justify-center h-8 w-8">
              {!isLayerVisible && (
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
              )}
              <Button
                title="Show full content"
                color="success"
                isIconOnly
                variant="solid"
                radius="full"
                onPress={() => setIsLayerVisible(!isLayerVisible)}
                className="flex items-center justify-center "
              >
                <motion.div
                  initial={{ rotate: 0 }}
                  animate={{ rotate: isLayerVisible ? 45 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <PlusIcon className="h-5 w-5 sm:h-6 sm:w-6" strokeWidth="3" />
                </motion.div>
              </Button>
            </span>
          </div>
        </div>
      </section>
    </AnimatedView>
  );
};
export default CompareSlider;

'use client';
import React from 'react';
import { motion } from 'framer-motion';
import { Image } from '@nextui-org/react';
import SectionMarkdown from '../markdown/section';

const AboutSection = ({ content, image }: any) => {
  return (
    <div
    // className="five-potted-succulent-plants-background"
    >
      <div className="flex flex-col sm:flex-row section py-8 sm:py-16 relative">
        {/* Image 3 */}
        <motion.img
          src="/assest/leafe-shap-1.png"
          alt=""
          className="hidden sm:flex object-contain absolute h-32 w-32 sm:left-[36%] sm:top-[59%]"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            y: [0, 30, 0],
            transition: {
              duration: 8,
              repeat: Infinity,
              repeatType: 'reverse',
              delay: 1,
            },
          }}
        />

        <div className="flex flex-col flex-1 py-4 sm:py-8 sm:pr-8">
          <div className="flex flex-col gap-1">
            <SectionMarkdown content={content} />
          </div>
        </div>
        <div className="flex flex-col flex-1 py-4 sm:py-8">
          <div className="flex-1 relative overflow-hidden rounded-2xl">
            <Image
              isZoomed
              src={image}
              alt="cover image about us"
              className="w-full h-full object-cover"
              classNames={{
                wrapper: 'w-full h-full',
                zoomedWrapper: 'w-full h-full',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;

'use client';

import React from 'react';
import { Link } from '@/navigation';
import { motion } from 'framer-motion';
import { cn } from '@nextui-org/react';
import { Content } from '@/typings/projects';
import AnimatedMaskImage from '../animated-mask';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';

type ListProps = {
  items: Content[];
  animationType: 'LEFT' | 'RIGHT';
};
type ServicesProps = {
  data: Content[];
  masks: any[];
};

const ListServices = ({ data, masks }: ServicesProps) => {
  return (
    <motion.section className="pb-16">
      <div className="overflow-hidden">
        <div className="section py-12">
          <div className="grid grid-cols-1 sm:grid-cols-[1fr_auto_1fr] gap-8">
            <List items={data?.slice(0, 5)} animationType="LEFT" />
            <div className="flex flex-1">
              <div className="flex flex-1 items-center justify-center">
                <div className="h-[480px] w-80 sm:rounded-full rounded-3xl overflow-hidden relative gradient-box-shadow">
                  <AnimatedMaskImage masks={masks} />
                </div>
              </div>
            </div>
            <List items={data?.slice(5, 10)} animationType="RIGHT" />
          </div>
        </div>
      </div>
    </motion.section>
  );
};
export default ListServices;

const List = ({ items, animationType }: ListProps) => {
  return (
    <div className="flex flex-1">
      <ul className="flex flex-col flex-1 gap-1">
        {items &&
          items?.map(({ title, slug }, i) => (
            <motion.li
              key={i}
              className={cn(
                'text-base sm:text-lg font-semibold sm:p-2 p-4 flex-1 flex items-center gap-1 border-b border-b-default-200 uppercase select-none cursor-pointer animate-svg '
              )}
              initial={{ opacity: 0, x: animationType === 'LEFT' ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: i * 0.1 }}
            >
              <Link
                className={cn(
                  'flex items-center gap-2 text-default-900',
                  animationType === 'RIGHT' &&
                    'sm:w-full sm:flex-row-reverse sm:justify-start sm:text-right'
                )}
                href={{
                  pathname: `/products-and-services/[slug]`,
                  params: {
                    slug: slug as any,
                  },
                }}
              >
                <ArrowRightCircleIcon
                  height={24}
                  width={24}
                  className="min-w-6"
                />
                <p className="line-clamp-1">{title}</p>
              </Link>
            </motion.li>
          ))}
      </ul>
    </div>
  );
};

'use client';

import React from 'react';
import View from '../view';
import Image from 'next/image';
import { motion } from 'framer-motion';
import Markdown from 'markdown-to-jsx';
import { MapPinIcon } from '@heroicons/react/24/outline';

type Props = {
  content: any;
};

const ProjectsMarkdown: React.FC<Props> = ({ content }) => {
  return (
    <Markdown
      options={{
        overrides: {
          h2: {
            component: (props) => (
              <h2 className="text-default-900 text-xl md:text-3xl lg:text-4xl font-semibold py-2">
                {props.children}
              </h2>
            ),
          },
          blockquote: {
            component: (props) => (
              <View className="flex flex-col gap-2">
                <motion.span
                  className="flex items-center gap-1 text-[var(--app-color-main)] text-sm sm:text-base italic font-semibold"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  <MapPinIcon className="h-4 w-4" />
                  {props.children}
                </motion.span>
              </View>
            ),
          },
          p: {
            component: (props) => (
              <View className="flex flex-col gap-2 py-2">
                <p className="text-[var(--app-color-main)] text-base sm:text-lg font-medium">
                  {props.children}
                </p>
              </View>
            ),
          },
          img: {
            component: (props) => (
              <div className="flex flex-col flex-1 relative">
                <Image
                  width={1280}
                  height={520}
                  alt={props.alt}
                  src={props.src}
                  className="w-full h-full max-h-[520px] object-cover rounded-lg"
                />
              </div>
            ),
          },
        },
      }}
    >
      {content}
    </Markdown>
  );
};

export default ProjectsMarkdown;

'use client';
import React from 'react';
import { motion } from 'framer-motion';
import { mountAnimation } from '@/config/animation';
import ProjectsMarkdown from '../markdown/projects';

type Props = {
  content?: string;
};

const Slider = ({ content }: Props) => {
  return (
    <motion.div {...mountAnimation} className="section relative">
      <ProjectsMarkdown content={content} />

      {/* {projectsData.map((item, index) => {
        return (
          <View key={index}>
            <motion.div className="flex flex-col gap-2 py-8">
              <h4 className="text-default-900 text-xl md:text-3xl lg:text-4xl font-semibold">
                {item?.heading}
              </h4>
              <p className="text-[var(--app-color-main)] text-base sm:text-lg font-medium">
                {item?.description}
              </p>
            </motion.div>
            <div key={index} className="flex flex-col flex-1 relative">
              <Image
                alt={item.source}
                width={1280}
                height={520}
                src={item.source}
                className="w-full h-full max-h-[520px] object-cover rounded-lg"
              />
            </div>
            <View className="flex flex-col gap-2 py-4">
              <motion.span
                className="flex items-center gap-1 text-[var(--app-color-main)] text-sm sm:text-base italic font-semibold"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <MapPinIcon className="h-4 w-4" />
                {item?.location}
              </motion.span>
            </View>
          </View>
        );
      })} */}
    </motion.div>
  );
};
export default Slider;

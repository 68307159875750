export const mountAnimation = {
  initial: {
    opacity: 0,
    y: 100,
  },
  whileInView: {
    opacity: 1,
    y: 0,
  },
  transition: {
    delay: 0.4,
    duration: 0.8,
  },
  viewport: {
    once: true,
  },
};
